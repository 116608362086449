
// Libraries
import * as React from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import DuoSection from '../../components/duoSection'
import Button from "../../components/button";
import {useContext, useEffect} from "react";
import BasketContext from "../../contexts/basket";

// Middleware
import CheckoutMiddleware from '../../middleware/checkoutMiddleware'

const BasketSuccessPage = () => {
	const { refresh } = useContext(BasketContext);

	useEffect(() => {
		refresh()
	}, [])

	return (
		<CheckoutMiddleware>
			<Layout className="page--success nav-blue-half no-footer">
				<Seo title="Ballots Submitted" />
				<DuoSection success BallotsSubmitted>
					<div>
						<div className="content-wrap">
							<h1>Ballots Submitted</h1>
							<p>
								Thank you for submitting your entry.<br />
								We will get in touch via email to let you know if your application was successful.<br />
								Good Luck!
							</p>
							<Button to="/events" hollow>Explore more events</Button>
						</div>
					</div>
					<div>
					</div>
				</DuoSection>
			</Layout>
		</CheckoutMiddleware>
	)
}

export default BasketSuccessPage
